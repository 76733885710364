/* eslint-disable react/prop-types */
import style from './CtaButton.module.scss';


export default function CtaButton({
	conteudo,
	classe,
	href,
	margin,
	fontSize
}) {
	return (
			<a
				href={href}
				style={{
					margin: margin,
					fontSize: fontSize
				}}
				className={classe ? classe + style.ctaButton : style.ctaButton}
			>
				<span className={style.ctaText}>{conteudo}</span>
				<span className={style.ctaIcon}>
					<svg
						width="27"
						height="25"
						viewBox="0 0 49 49"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M3.0625 24.5C3.0625 24.0939 3.22383 23.7044 3.51099 23.4172C3.79816 23.1301 4.18764 22.9687 4.59375 22.9687H40.7098L31.0721 13.3341C30.7846 13.0466 30.6231 12.6566 30.6231 12.25C30.6231 11.8434 30.7846 11.4534 31.0721 11.1659C31.3597 10.8783 31.7496 10.7168 32.1562 10.7168C32.5629 10.7168 32.9528 10.8783 33.2404 11.1659L45.4904 23.4159C45.633 23.5581 45.7461 23.7271 45.8233 23.9131C45.9005 24.0991 45.9402 24.2986 45.9402 24.5C45.9402 24.7014 45.9005 24.9008 45.8233 25.0869C45.7461 25.2729 45.633 25.4419 45.4904 25.5841L33.2404 37.8341C32.9528 38.1216 32.5629 38.2832 32.1562 38.2832C31.7496 38.2832 31.3597 38.1216 31.0721 37.8341C30.7846 37.5466 30.6231 37.1566 30.6231 36.75C30.6231 36.3434 30.7846 35.9534 31.0721 35.6659L40.7098 26.0312H4.59375C4.18764 26.0312 3.79816 25.8699 3.51099 25.5828C3.22383 25.2956 3.0625 24.9061 3.0625 24.5V24.5Z"
						></path>
					</svg>
				</span>
			</a>
	);
}

import style from './ContactBar.module.scss';

export default function ContactBar({ contact, setModal }) {
	return (
		<div className={style.bar}>
			<a href="/fale-conosco/quero-comprar">
				<div className={style.item}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="19"
						height="15.2"
						viewBox="0 0 19 15.2"
					>
						<path
							id="Icon_material-mail-outline"
							data-name="Icon material-mail-outline"
							d="M20.1,6H4.9A1.9,1.9,0,0,0,3.01,7.9L3,19.3a1.906,1.906,0,0,0,1.9,1.9H20.1A1.906,1.906,0,0,0,22,19.3V7.9A1.906,1.906,0,0,0,20.1,6Zm0,13.3H4.9V9.8l7.6,4.75L20.1,9.8Zm-7.6-6.65L4.9,7.9H20.1Z"
							transform="translate(-3 -6)"
							fill="#fff"
						/>
					</svg>
					Fale conosco
				</div>
			</a>
			{contact.map((item, index) => {
				if (item.show_modal.data) {
					return (
						<a key={'contactDesktop' + index} onClick={() => setModal(item.name.data)}>
							<div className={style.item}>
								<div
									className={
										style.contactLogo
									}
									dangerouslySetInnerHTML={{
										__html: item['icon_svg']
											.data
									}}
								></div>
								<span>{item.name.data}</span>
							</div>
						</a>
					)
				}								
				return (
					<a key={'contactDesktop' + index} target={item.link.data[0] == '/'? "_self" : "_blank"} href={item.link.data} rel="noreferrer">
						<div className={style.item}>
							<div
								className={
									style.contactLogo
								}
								dangerouslySetInnerHTML={{
									__html: item['icon_svg']
										.data
								}}
							></div>
							<span>{item.name.data}</span>
						</div>
					</a>
				);
			})}
		</div>
	);
}

// import Link from 'next/link';
import { useEffect, useState } from 'react';
import style from './Header.module.scss';
import ContactBar from '../ContactBar-new';
import CtaButton from '../CtaButton';
import ApiController from 'providers/ApiController/ApiController';


export default function Header(data) {
	const [transparentHeader, setTransparentHeader] = useState(
		data.transparent
	);
	const [mobileMenu, setMobileMenu] = useState(false);
	const [contactModal, setContactModal] = useState(false);
    
	const [menuHeader, setMenuHeader] = useState(false);
	const [contactHeader, setContactHeader] = useState(false);
	const [modalText, setModalText] = useState(false);
	const [modalLinks, setModalLinks] = useState(false);
	const [clientAreaLink, setClientAreaLink] = useState(false);
	const [contactBar, setContactBar] = useState(false);

	const [modalLinkWpp, setModalLinkWpp] = useState(false);
	const [modalLinkChat, setModalLinkChat] = useState(false);

    // load page content
	  useEffect(() => {
		const apiCtrl = new ApiController();

		if(!menuHeader){
			const requestMenu = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'header_menu',
					returnAs: 'menuHeader',
				},
			]);

			requestMenu.then(({ success, result }) => {
				if (success) {
					let data = result.menuHeader.data;
					setMenuHeader(data);
				}
			});
		}

		if(!contactHeader){
			const requestContact = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'fale_conosco',
					returnAs: 'faleConosco',
				},
			]);

			requestContact.then(({ success, result }) => {
				if (success) {
					let data = result.faleConosco.data;
					setContactHeader(data);
				}
			});
		}

		if(!modalText){
			const requestModalText = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'header_modal_text',
					returnAs: 'text',
				},
			]);

			requestModalText.then(({ success, result }) => {
				if (success) {
					let data = result.text.data;
					setModalText(data);
				}
			});
		}

		if(!modalLinks){
			const requestModalLinks = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'header_modal',
					returnAs: 'links',
				},
			]);

			requestModalLinks.then(({ success, result }) => {
				if (success) {
					let data = result.links.data;
					setModalLinks(data);
				}
			});
		}

        if(!clientAreaLink){
			const requestClientAreaLink = apiCtrl.fetch([
                {
                    type: 'field',
                    idField: 'client_area_link',
                    returnAs: 'clienteArea'
                }
			]);

			requestClientAreaLink.then(({ success, result }) => {
				if (success) {
					let data = result.clienteArea.data;
					setClientAreaLink(data);
				}
			});
		}

        if(!contactBar){
			const requestContactBar = apiCtrl.fetch([
                {
                    type: 'field',
                    idField: 'fale_conosco',
                    returnAs: 'faleConosco'
                }
			]);

			requestContactBar.then(({ success, result }) => {
				if (success) {
					let data = result.faleConosco.data;
					setContactBar(data);
				}
			});
		}

        if(!modalLinkWpp){
			const requestModaLinksWpp = apiCtrl.fetch([
                {
                    type: 'field',
                    idField: 'header_modal_whatsapp_link',
                    returnAs: 'modalLinkWpp'
                }
			]);

			requestModaLinksWpp.then(({ success, result }) => {
				if (success) {
					let data = result.modalLinkWpp.data;
					setModalLinkWpp(data);
				}
			});
		}

        if(!modalLinkChat){
			const requestModaLinksChat = apiCtrl.fetch([
                {
                    type: 'field',
                    idField: 'header_modal_chat_link',
                    returnAs: 'modalLinkChat'
                }
			]);

			requestModaLinksChat.then(({ success, result }) => {
				if (success) {
					let data = result.modalLinkChat.data;
					setModalLinkChat(data);
				}
			});
		}

	  }, []);

	useEffect(() => {
		const headerObserver = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && !mobileMenu && window.location.pathname.indexOf('fale-conosco') === -1) {
				setTransparentHeader(true);
			} else {
				setTransparentHeader(false);
			}
		});

		const header = document.getElementById('headerObserver');
		headerObserver.observe(header);
	});

	function ContactModal(props) {
		const { text, links, chat, whatsapp } = props.modal;
		let hypnoboxLink;
		if (contactModal == 'Chat') hypnoboxLink = chat;
		else if (contactModal == 'WhatsApp') hypnoboxLink = whatsapp;
        

        console.log('aaquiiiii --------');
        console.log(props);

		return (
			<div className={style.modalBackground}>
				<div className={style.modal}>
					<div
						className={style.closeContainer}
						onClick={() => setContactModal(false)}
					>
						<span className={style.modalClose} />
					</div>
					<div dangerouslySetInnerHTML={{ __html: text }}></div>
					<div className={style.modalButtonsContainer}>
						<a
							key={'contactModal' + 0}
							href={hypnoboxLink}
							target="_blank"
							className={style.modalButton}
							rel="noreferrer"
						>
							Quero Comprar um Ekko
						</a>
						{links.map((button, index) => (
							<a
								key={'contactModal' + (index + 1)}
								href={button.link.data}
								target={
									button.link.data[0] == '/'
										? '_self'
										: '_blank'
								}
								className={style.modalButton}
								rel="noreferrer"
							>
								{button.label.data}
							</a>
						))}
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<span className={style.headerObserver} id="headerObserver"></span>
			<header
				className={
					transparentHeader
						? style.header
						: style.header + ' ' + style.headerFill
				}
			>
				<div className={style.leftWrapper}>

						<a href="/" className={style.logoWrapper}>
							<img
								className='imageFill'
								src="/logo_branco.svg"
								alt="Logo da Ekko Group"
								title="Ekko Group"
							/>
						</a>

				</div>
				<div className={style.rightWrapper} id="header_rightWrapper">
					<button
						className={style.menuButton}
						onClick={() => {
							setMobileMenu(!mobileMenu);
							setTransparentHeader(false);
						}}
						id="header_menuButton"
					>
						<span>Menu</span>
						<div className={style.menuLogo}>
							<svg
								width="29"
								height="25"
								viewBox="0 0 14 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M13.4 4H0.6C0.0479999 4 0 4.447 0 5C0 5.553 0.0479999 6 0.6 6H13.4C13.952 6 14 5.553 14 5C14 4.447 13.952 4 13.4 4V4ZM13.4 8H0.6C0.0479999 8 0 8.447 0 9C0 9.553 0.0479999 10 0.6 10H13.4C13.952 10 14 9.553 14 9C14 8.447 13.952 8 13.4 8V8ZM0.6 2H13.4C13.952 2 14 1.553 14 1C14 0.447 13.952 0 13.4 0H0.6C0.0479999 0 0 0.447 0 1C0 1.553 0.0479999 2 0.6 2Z" />
							</svg>
						</div>
					</button>
					<div
						className={
							mobileMenu
								? style.mobileItems
								: style.mobileItems + ' ' + style.closed
						}
						id="header_mobileItems"
					>
						<div className={style.headerLinksWrapper}>
							{ menuHeader ? menuHeader.map((item, index) => {
								return (
									<div
										key={'headerMenuMobile' + index}
										className={style.headerLinks}
									>
										<a
											target={
												item.link.data[0] == '/'
													? '_self'
													: '_blank'
											}
											href={item.link.data}
											className={style.headerLink}
											rel="noreferrer"
										>
											{item.title.data}
										</a>
										{item.has_submenu.data && (
											<ul className={style.submenu}>
												{item.submenu.data.map(
													(subitem, index) => {
														return (
															<li
																key={
																	item.title
																		.data +
																	index
																}
															>
																<a
																	rel="noreferrer"
																	target={
																		subitem
																			.link
																			.data[0] ==
																		'/'
																			? '_self'
																			: '_blank'
																	}
																	href={
																		subitem
																			.link
																			.data
																	}
																>
																	{
																		subitem
																			.title
																			.data
																	}
																	{subitem
																		.external
																		.data && (
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="10px"
																			height="10px"
																		>
																			<path d="M9.384,4.671 C9.044,4.671 8.767,4.395 8.767,4.055 L8.767,2.104 L5.514,5.358 C5.397,5.474 5.242,5.538 5.078,5.538 C4.913,5.538 4.758,5.474 4.642,5.358 C4.526,5.242 4.462,5.087 4.462,4.922 C4.462,4.757 4.526,4.603 4.642,4.486 L7.896,1.233 L5.945,1.233 C5.605,1.233 5.329,0.956 5.329,0.616 C5.329,0.277 5.605,-0.000 5.945,-0.000 L9.384,-0.000 C9.723,-0.000 10.000,0.277 10.000,0.616 L10.000,4.055 C10.000,4.395 9.723,4.671 9.384,4.671 ZM5.590,2.916 L1.057,2.916 L1.057,8.943 L7.084,8.943 L7.084,4.410 L8.141,3.353 L8.141,9.472 C8.141,9.763 7.904,10.000 7.613,10.000 L0.528,10.000 C0.237,10.000 -0.000,9.763 -0.000,9.472 L-0.000,2.387 C-0.000,2.096 0.237,1.859 0.528,1.859 L6.647,1.859 L5.590,2.916 Z"></path>
																		</svg>
																	)}
																</a>
															</li>
														);
													}
												)}
											</ul>
										)}
									</div>
								);
							}) : '' }
						</div>
						<div className={style.contact}>
								<a href="/fale-conosco">
									Fale conosco
								</a>
							{ contactHeader ? contactHeader.map((item, index) => {
								if (item.show_modal.data) {
									return (
										<a
											key={'modalMobile' + index}
											onClick={() =>
												setContactModal(item.name.data)
											}
										>
											<div className={style.contactLink}>
												<div
													className={
														style.contactLink
													}
													dangerouslySetInnerHTML={{
														__html: item['icon_svg']
															.data
													}}
												></div>
												<span>{item.name.data}</span>
											</div>
										</a>
									);
								}

								return (
									<a
										key={'contactMobile' + index}
										target={
											item.link.data[0] == '/'
												? '_self'
												: '_blank'
										}
										href={item.link.data}
										rel="noreferrer"
									>
										<div className={style.contactLink}>
											<div
												className={style.contactLogo}
												dangerouslySetInnerHTML={{
													__html: item['icon_svg']
														.data
												}}
											></div>
											<span>{item.name.data}</span>
										</div>
									</a>
								);
							}) : '' }
						</div>
					</div>
					<div className={style.desktopItems}>
						{ menuHeader ? menuHeader.map((item, index) => {
							return (
								<div
									key={'headerMenuDesktop' + index}
									className={style.headerLinks}
								>
									<a
										rel="noreferrer"
										target={
											item.link.data[0] == '/'
												? '_self'
												: '_blank'
										}
										href={item.link.data}
										className={style.headerLink}
									>
										{item.title.data}
									</a>
									{item['has_submenu'].data && (
										<ul className={style.submenu}>
											{item.submenu.data.map(
												(subitem, index) => {
													return (
														<li
															key={
																item['title']
																	.data +
																index
															}
														>
															<a
																rel="noreferrer"
																target={
																	subitem.link
																		.data[0] ==
																	'/'
																		? '_self'
																		: '_blank'
																}
																href={
																	subitem.link
																		.data
																}
															>
																{
																	subitem
																		.title
																		.data
																}
																{subitem
																	.external
																	.data && (
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="10px"
																		height="10px"
																	>
																		<path d="M9.384,4.671 C9.044,4.671 8.767,4.395 8.767,4.055 L8.767,2.104 L5.514,5.358 C5.397,5.474 5.242,5.538 5.078,5.538 C4.913,5.538 4.758,5.474 4.642,5.358 C4.526,5.242 4.462,5.087 4.462,4.922 C4.462,4.757 4.526,4.603 4.642,4.486 L7.896,1.233 L5.945,1.233 C5.605,1.233 5.329,0.956 5.329,0.616 C5.329,0.277 5.605,-0.000 5.945,-0.000 L9.384,-0.000 C9.723,-0.000 10.000,0.277 10.000,0.616 L10.000,4.055 C10.000,4.395 9.723,4.671 9.384,4.671 ZM5.590,2.916 L1.057,2.916 L1.057,8.943 L7.084,8.943 L7.084,4.410 L8.141,3.353 L8.141,9.472 C8.141,9.763 7.904,10.000 7.613,10.000 L0.528,10.000 C0.237,10.000 -0.000,9.763 -0.000,9.472 L-0.000,2.387 C-0.000,2.096 0.237,1.859 0.528,1.859 L6.647,1.859 L5.590,2.916 Z"></path>
																	</svg>
																)}
															</a>
														</li>
													);
												}
											)}
										</ul>
									)}
								</div>
							);
						}) : ''}

						<div className={style.contact} id="header_contact">
								<a href="/fale-conosco" className={style.contactUs}>
									Fale conosco
								</a>
							{ contactHeader ? contactHeader.map((item, index) => {
								if (item.show_modal.data) {
									return (
										<a
											key={'modalDesktop' + index}
											onClick={() =>
												setContactModal(item.name.data)
											}
										>
											<div className={style.contactLink}>
												<div
													className={
														style.contactLogo
													}
													dangerouslySetInnerHTML={{
														__html: item['icon_svg']
															.data
													}}
												></div>
												<span>{item.name.data}</span>
											</div>
										</a>
									);
								}
								return (
									<a
										rel="noreferrer"
										key={'contactDesktop' + index}
										target={
											item.link.data[0] == '/'
												? '_self'
												: '_blank'
										}
										href={item.link.data}
									>
										<div className={style.contactLink}>
											<div
												className={style.contactLogo}
												dangerouslySetInnerHTML={{
													__html: item['icon_svg']
														.data
												}}
											></div>
											<span>{item.name.data}</span>
										</div>
									</a>
								);
							}) : ''}
						</div>
						{( clientAreaLink && clientAreaLink.link !== "")? 
						<a
							className={style.clientArea}
							href={data.clientArea.link}
						>
							{data.clientArea.text}
						</a>	
						:
						<a
							className={style.clientArea}
						>
						</a>
						}
						
					</div>
				</div>
				{ ( contactModal && modalLinkWpp && modalLinkChat && modalText && modalLinks ) && 
                    <ContactModal 
                        modal={ {
                            text: modalText,
                            whatsapp: modalLinkWpp,
                            chat: modalLinkChat,
                            links: modalLinks
                         } } 
                    />}
			</header>
            { contactBar ? 
			    <ContactBar setModal={setContactModal} contact={contactBar} />
            : ''}
			<div
				className={style.chatButton}
				onClick={() => setContactModal('Chat')}
			>
				<CtaButton href="#" conteudo="Chat" />
			</div>
		</>
	);
}

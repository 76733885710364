const axios = require('axios').default;

const Mtn = require('providers/Util/Mtn').default;
class ApiController {
  /**
   * constructor
   *
   * This is the main constructor for
   * the provider.
   *
   * @type function
   * @since 0.0.1
   */
  constructor() {
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();

    /** @var object */
    // para produção
    // const baseUrl = 'https://api.ekkogroup.com.br';
    
    //para dev
    // const baseUrl = "https://ekko-dev.ironman.mtnapp.net/api";
    const baseUrl = "https://projetos-php8.mtnapp.net/ekkogroup/api"

    /** @var string */
    this.url_adminAjax = `${baseUrl}/wp-admin/admin-ajax.php`;

    /** @var string */
    this.url_forms = `${baseUrl}/wp-json/contact-form-7/v1/contact-forms`;
  }

  /**
   * fetch
   *
   * Attempts to fetch the specified
   * data from the backend.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object params
   * @return promise
   */
  fetch = async params => {
    try {
      const response = await axios({
        method: 'post',
        url: this.buildUrl({
          action: 'fetchData',
        }),
        data: params,
        cancelToken: this.source.token,
      });

      return {
        success: true,
        result: response.data.results,
      }
    } catch (err) {
      return {
        success: false,
        err,
      }
    }
  }

  /**
   * sendForm
   *
   * Sends the specified form data.
   *
   * @type function
   * @since 0.0.1
   *
   * @param number formId
   * @param object formData
   * @param object postFields=false
   * @return promise
   */
  sendForm = async (formId, formData, postFields=false) => {
    // prepare the form data
    const data = new URLSearchParams();
    
    let origin = Mtn.getRdMediumInfo().source;
    for (let key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios({
        method: 'post',
        url: this.buildUrl({
          type: 'forms',
          formId,
        }),
        data,
        cancelToken: this.source.token,
      });

      if ((postFields && postFields['hypnobox-switch']) || formId === 835) {
        const hypnoboxData = JSON.stringify({
          id_produto: postFields["hypnobox-id"] ?? formData.hypnoboxid ?? "20",
          mensagem: formData.mensagem ?? `${new Date().getTime()}`,
          nome: formData.nome,
          email: formData.email,
          ddd_celular: formData.telefone.substr(1, 2),
          tel_celular: formData.telefone.substr(5).replace('-', ''),
          midia:origin,
          privacidade: formData.privacidade
        });

        await axios({
          method: 'post',
          url: this.buildUrl({
            action: 'sendToHypnobox',
          }),
          data: hypnoboxData,
          cancelToken: this.source.token,
        });
      }

      return {
        success: response.data.status === 'mail_sent',
      }
    } catch (err) {
      return {
        success: false,
      }
    }
  }

  /**
   * uploadFiles
   *
   * Uploads the specified files
   * to WordPress.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object files
   * @return object
   */
  uploadFiles = async files => {
    const response = await axios({
      method: 'post',
      url: this.buildUrl({
        action: 'uploadFiles',
      }),
      data: files,
      cancelToken: this.source.token,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }

  /**
   * buildUrl
   *
   * Builds a request Url from
   * the specified action.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object options
   * @return string
   */
  buildUrl = options => {
    switch (options.type) {
      case 'forms':
        return `${this.url_forms}/${options.formId}/feedback`;

      default:
        return `${this.url_adminAjax}?action=${options.action}`;
    }
  }

  /**
   * cleanup
   *
   * Cancels all requests being made.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return NA
   */
  cleanup = () => this.source.cancel();
}

export default ApiController;

import style from './Modal.module.scss';

export default function Modal({ title, text, setModal }) {
	function closeModal() {
		setModal(false);
	}

	return (
		<section className={style.modal}>
			<div className={style.modalWrapper}>
				<button className={style.closeButton} onClick={closeModal}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
						width="23px"
						height="22px"
					>
						<path
							d="M12.216,10.999 L21.750,1.465 C22.086,1.130 22.086,0.585 21.750,0.250 C21.415,-0.086 20.870,-0.086 20.535,0.250 L11.000,9.784 L1.465,0.250 C1.130,-0.086 0.585,-0.086 0.250,0.250 C-0.086,0.585 -0.086,1.130 0.250,1.465 L9.784,10.999 L0.250,20.533 C-0.086,20.868 -0.086,21.413 0.250,21.748 C0.418,21.916 0.638,22.000 0.858,22.000 C1.078,22.000 1.298,21.916 1.465,21.748 L11.000,12.215 L20.535,21.748 C20.702,21.916 20.922,22.000 21.142,22.000 C21.362,22.000 21.582,21.916 21.750,21.748 C22.086,21.413 22.086,20.868 21.750,20.533 L12.216,10.999 Z"
						></path>
					</svg>
				</button>
				<h1 className={style.title}>{title}</h1>
				<div className={style.contentWrapper}  dangerouslySetInnerHTML={{__html: text}}>
				</div>
			</div>
		</section>
	);
}

import React, { Suspense } from 'react';
import 'App.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import {
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';

import routes from 'routes';

import ScrollToTop from 'components/ScrollTop/ScrollTop';
// import Header from 'components/Header/Header.js';
import Header from 'components/Header-new/index.jsx';
// import Footer from 'components/Footer/Footer.js';
import Footer from 'components/Footer-new/index.jsx';
import PrivacyTextModal from 'components/PrivacyTextModal/PrivacyTextModal'

import { PrivacyContextProvider } from 'context/PrivacyContext'

/** @var object */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#b6d600',
    },
    secondary: {
      main: '#333',
    },
  },
});

const App = () => {
  return (
        <PrivacyContextProvider>
    <ThemeProvider theme={theme}>
      <PrivacyTextModal />
        <div className="App">
          <Router>
            <Header />
            <ScrollToTop>
              <Suspense fallback={''}>
                <Switch>
                  {routes.map(
                    ({ Component, ...route }, key) => (
                      <Route
                        key={key}
                        exact={route.exact}
                        path={route.path}
                        render={renderProps => (
                          <Component {...renderProps} routeProps={route} />
                        )}
                      />
                    )
                  )}
                </Switch>
              </Suspense>
            </ScrollToTop>
            <Footer />
          </Router>
        </div>
      </ThemeProvider>
    </PrivacyContextProvider>
  );
}

export default App;

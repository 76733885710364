import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from './Footer.module.scss';
import Modal from '../Modal-Next';
import Form from '../Form-Next';

import { useEffect, useState } from 'react';
import Mtn from 'providers/Util/Mtn';
import ApiController from 'providers/ApiController/ApiController';


export default function Footer(data) {
	const configSlider = {
		arrows: true,
		dots: false,
		infinite: false,
		slidesToShow: 2,
		slidesToScroll: 2,
		adaptiveHeight: false,
		draggable: true,
		touchMove: true,
		className: 'carrosselPremiosFooter',
	};

	const [modalPrivacy, setModalPrivacy] = useState(false);
	const [modalQuality, setModalQuality] = useState(false);

	const [locations, setLocations] = useState(false);
	const [menuFooter, setMenuFooter] = useState(false);
	const [prizes, setPrizes] = useState(false);
	const [social, setSocial] = useState(false);
	const [modalPrivacyText, setModalPrivacyText] = useState(false);
	const [modalQualityText, setModalQualityText] = useState(false);
	Mtn.createQueryString();

	// load page content
	useEffect(() => {
		const apiCtrl = new ApiController();

		if (!locations) {
			const requestLocations = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'footer_locations_groups',
					returnAs: 'locations',
				},
			]);

			requestLocations.then(({ success, result }) => {
				if (success) {
					let data = result.locations.data;
					setLocations(data);
				}
			});
		}

		if (!menuFooter) {
			const requestMenuFooter = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'footer_menu',
					returnAs: 'menu',
				},
			]);

			requestMenuFooter.then(({ success, result }) => {
				if (success) {
					let data = result.menu.data;
					setMenuFooter(data);
				}
			});
		}

		if (!modalPrivacyText) {
			const requestModalPrivacyText = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'footer_politica_de_privacidade',
					returnAs: 'privacy',
				},
			]);

			requestModalPrivacyText.then(({ success, result }) => {
				if (success) {
					let data = result.privacy.data;
					setModalPrivacyText(data);
				}
			});
		}

		if (!modalQualityText) {
			const requestModalQualityText = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'footer_politica_de_qualidade',
					returnAs: 'quality',
				},
			]);

			requestModalQualityText.then(({ success, result }) => {
				if (success) {
					let data = result.quality.data;
					setModalQualityText(data);
				}
			});
		}

		if (!prizes) {
			const requestPrizes = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'footer_prizes',
					returnAs: 'prizes',
				},
			]);

			requestPrizes.then(({ success, result }) => {
				if (success) {
					let data = result.prizes.data;
					setPrizes(data);
				}
			});
		}

		if (!social) {
			const requestSocial = apiCtrl.fetch([
				{
					type: 'field',
					idField: 'footer_social_networks',
					returnAs: 'social',
				},
			]);

			requestSocial.then(({ success, result }) => {
				if (success) {
					let data = result.social.data;
					setSocial(data);
				}
			});
		}
	}, []);

	return (
		<footer className={style.footer}>
			<img
				className={style.background + ' imageFill'}
				src='/footer-background.jpg'
				alt="Ekko Group"
			/>
			<div className={style.footerWrapper + ' container'}>
				<div className={style.locations}>
					<h2 className={style.title}>Onde estamos</h2>
					{locations ? locations.map((location, index) => (
						<div
							className={style.location}
							key={'location' + index}
						>
							<h3 className={style.locationName}>
								{location.title.data}
							</h3>
							<div className={style.locationAddress}
								dangerouslySetInnerHTML={{ __html: location.address.data }}>
							</div>
							{location['phone-number'].data != '' ? (
								<a href={'tel:' + location['phone-number'].data} className={style.locationPhone}>
									<svg
										width="11"
										height="15"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M17.487 14.14L13.422 10.444C13.2299 10.2693 12.9774 10.1762 12.7178 10.1842C12.4583 10.1922 12.212 10.3008 12.031 10.487L9.63801 12.948C9.06201 12.838 7.90401 12.477 6.71201 11.288C5.52001 10.095 5.15901 8.934 5.05201 8.362L7.51101 5.968C7.69742 5.78712 7.80614 5.54082 7.81417 5.2812C7.82219 5.02159 7.72889 4.76904 7.55401 4.57699L3.85901 0.512995C3.68405 0.320352 3.44089 0.203499 3.18116 0.187255C2.92143 0.17101 2.66561 0.256653 2.46801 0.425995L0.298008 2.28699C0.125119 2.46051 0.0219269 2.69145 0.0080073 2.93599C-0.0069927 3.18599 -0.292993 9.108 4.29901 13.702C8.30501 17.707 13.323 18 14.705 18C14.907 18 15.031 17.994 15.064 17.992C15.3085 17.9783 15.5393 17.8747 15.712 17.701L17.572 15.53C17.742 15.333 17.8283 15.0774 17.8124 14.8177C17.7965 14.558 17.6798 14.3148 17.487 14.14V14.14Z"
											fill="#38AC56"
										/>
									</svg>
									{location['phone-number'].data}
								</a>
							) : (
								''
							)}
							{location.email.data != '' ? (
								<a href={'mailto:' + location.email.data} className={style.locationEmail}>
									<svg
										width="11"
										height="15"
										viewBox="0 0 20 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
											fill="#38AC56"
										/>
									</svg>
									{location.email.data}
								</a>
							) : (
								''
							)}
						</div>
					)) : ''}
				</div>
				<div className={style.menu}>
					{menuFooter ? menuFooter.map((item, index) => (
						<a key={'footerMenu' + index} className={style.menuItem} href={item.link.data} target="_blank" rel="noreferrer">
							{item.name.data}
						</a>
					)) : ''}
				</div>

				<div className={style.group}>
					<div className={style.awards}>
						<h2 className={style.title}>Premiações</h2>
						<Slider {...configSlider}>
						{prizes ? prizes.map((prize, index) => (
							<img key={'prizes' + index} src={prize.image.data.url} title={prize.image.data.alt} alt={prize.image.data.alt} />
						)) : ''}
						</Slider>
					</div>
					<div className={style.social}>
						<h2 className={style.title}>Siga e compartilhe</h2>
						<div className={style.socialContainer}>
							{social ? social.map((network, index) => (
								<a className={style.socialLink}
									key={'social' + index}
									aria-label={network.name.data}
									href={network.link.data}
									dangerouslySetInnerHTML={{ __html: network.icon.data }}
								></a>
							)) : ''}
						</div>
					</div>
				</div>
				<p className={style.copyright}>
					© 2021 • Ekko Group • Todos os direitos reservados • All
					rights reserved
					<span
						className={style.policies}
						onClick={() => setModalQuality(true)}
						aria-label="Abrir modal"
					>
						Política de Qualidade
					</span>
					<span
						className={style.policies}
						onClick={() => setModalPrivacy(true)}
						aria-label="Abrir modal"
					>
						Política de Privacidade
					</span>
					<a
						href="https://api.ekkogroup.com.br/wp-content/uploads/2023/05/PO-SGI-01_Politica_do_Sistema_de_Gestao_Integrado_R00.pdf"
						target="_blank"
						className={style.policies}
					>
						Política da Ekko Construções
					</a>
				</p>
				{modalPrivacy && (
					<Modal
						title="Política de Privacidade"
						text={modalPrivacyText}
						setModal={setModalPrivacy}
					/>
				)}
				{modalQuality && (
					<Modal
						title="Política de Qualidade"
						text={modalQualityText}
						setModal={setModalQuality}
					/>
				)}
			</div>

		</footer>
	);
}
